import React from "react"
import { graphql } from "gatsby"
import PageLayout from "./page-layout"
import BlogCard from "../components/blog-card"
import Wrapper from "../components/wrapper"

export default function AuthorLayout({
  data: {
    markdownRemark: {
      frontmatter: { avatar, fullName },
    },
    allMarkdownRemark,
  },
  pageContext: { lang },
}) {
  const isAR = lang === "ar"
  return (
    <PageLayout
      showBreadcrumbs={false}
      mainImage={avatar?.childImageSharp?.fluid || {}}
      title={`${isAR ? "مقالات بواسطة" : "Posts by"}: ${fullName}`}
    >
      <Wrapper style={{ mt: 5 }}>
        {allMarkdownRemark.edges?.map(({ node }) => (
          <BlogCard {...node.frontmatter} sx={{ mb: 4 }} key={node.id} />
        ))}
      </Wrapper>
    </PageLayout>
  )
}

export const authorQuery = graphql`
  query($name: String!, $lang: String!) {
    markdownRemark(frontmatter: { name: { eq: $name }, lang: { eq: $lang } }) {
      frontmatter {
        fullName
        avatar {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { author: { eq: $name }, lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "YYYY. MM. DD")
            author
            category
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
